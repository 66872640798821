<template>
  <b-card>
    <div>
      <b-row
        align-h="between"
        align-v="center"
        class="ml-1"
      >
        <b-col
          lg="2"
          md="3"
          sm="4"
          xs="12"
        >
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{
              $t("common.perPage")
            }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              class="w-50"
              @change="updatePerPage"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-cols-sm="1"
            label-align-sm="right"
            label-for="filterInput"
            class="mr-1"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('common.search')"
                class="mr-1"
                @keyup="searchItem"
              />

              <b-button
                type="reset"
                variant="primary"
                :to="{ path: 'users/new' }"
              >
                {{ $t("user.new_user") }}
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="mx-2 mt-1">
        <b-table
          striped
          hover
          responsive
          class="position-relative rows-responsive"
          :per-page="perPage"
          :items="data"
          :fields="fieldsRefresh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :busy="isBusy"
          show-empty
          @row-clicked="editItem"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #empty="scope">
            <b-row align-h="center">
              <span>{{ scope.emptyText }}</span>
            </b-row>
          </template>
          <template #cell(name)="cellName">
            {{ `${cellName.value} ${cellName.item.surname}` }}
          </template>
          <template #cell(status)="cellStatus">
            <b-badge :variant="status[1][cellStatus.value]">
              {{ statusRefresh[0][cellStatus.value] }}
            </b-badge>
          </template>
          <template #cell(role)="cellRole">
            <span>{{ $t(`common.${cellRole.item.role.toLowerCase()}`) }}</span>
          </template>
        </b-table>
      </div>

      <b-card-body
        class="d-flex justify-content-between align-items-center flex-wrap pt-0"
      >
        <!-- pagination -->
        <div>
          <b-pagination
            v-if="!isBusy && currentTablePage != null && totalRows != 0"
            v-model="currentTablePage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            :busy="isBusy"
            @change="pagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

        <!-- page length -->
        <span
          class="d-none d-md-block"
        >{{ $t("common.displaying") }}
          {{
            perPage * currentTablePage > totalRows
              ? totalRows
              : perPage * currentTablePage
          }}
          {{ $t("common.of") }} {{ totalRows }}
          {{ $t("common.registers") }}.</span>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BPagination,
  BTable,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BPagination,
    BTable,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BBadge,
    BSpinner,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      default: () => false,
    },
    totalRows: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      settings: [],
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      statusRefresh: [],
      fieldsRefresh: [],
      fields: [
        { key: 'username', label: 'user.username', sortable: true },
        { key: 'name', label: 'user.full_name', sortable: true },
        { key: 'email', label: 'user.email', sortable: true },
        { key: 'phone', label: 'user.phone', sortable: true },
        { key: 'status', label: 'user.status', sortable: true },
        { key: 'role', label: 'user.role', sortable: true },
      ],
      status: [
        {
          1: 'common.caps_active',
          0: 'common.caps_inactive',
        },
        {
          1: 'light-success',
          2: 'light-danger',
        },
      ],
      currentTablePage: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeUpdate() {
    this.languageRefresh()
  },
  mounted() {
    if (this.$route.query.page) {
      this.currentTablePage = Number(this.$route.query.page)
    } else {
      this.currentTablePage = 1
    }
  },
  methods: {
    searchItem() {
      this.$emit('search', this.filter)
    },
    editItem(item) {
      this.$router.push({ path: `/users/users/${item.users_id}`, query: { page: this.$route.query.page || 1 } })
    },
    languageRefresh() {
      this.statusRefresh = JSON.parse(JSON.stringify(this.status))
      this.fieldsRefresh = JSON.parse(JSON.stringify(this.fields))

      this.statusRefresh.forEach((_, index) => {
        this.statusRefresh[0][index] = `${this.$i18n.t(this.status[0][index])}`
      })

      this.fieldsRefresh.forEach((_, index) => {
        this.fieldsRefresh[index].label = `${this.$i18n.t(
          this.fields[index].label,
        )}`
      })
    },
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length
    // },
    pagination(page) {
      // const query = { ...this.$route.query, page }
      // this.$router.replace({ query })
      // this.$store.commit('app/SET_CURRENT_TABLE_PAGE', page)
      this.currentTablePage = page
      this.$emit('changePage', page)
    },
    updatePerPage(size) {
      this.$emit('onPerPage', size)
    },
  },
}
</script>
<style scoped></style>
