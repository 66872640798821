<template>
  <div>
    <users-table
      v-if="isAdmin"
      :data="items"
      :is-busy="isBusy"
      :total-rows="totalItems"
      @changePage="onPagination"
      @onPerPage="onPerPage"
      @search="search"
    />
    <b-card v-else>
      <div>
        <b-row
          align-h="center"
          align-v="center"
          class="ml-1"
        >
          <feather-icon
            icon="AlertOctagonIcon"
            class="text-danger"
            size="20"
          />
          <p
            class="text-danger mb-0 ml-1"
          >
            Invalid Permission
          </p>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import { BRow, BCard } from 'bootstrap-vue'
import UsersTable from '@/views/drs/users/components/UsersTable.vue'
import dbUser from '@/models/user'

export default {
  components: {
    UsersTable,
    BRow,
    BCard,
  },
  data() {
    return {
      items: [],
      isBusy: false,
      perPage: 50,
      totalItems: null,
      searchItem: '',
    }
  },
  computed: {
    isAdmin() {
      return JSON.parse(window.localStorage.userData).role === 'ADMIN'
    },
  },
  async created() {
    if (this.$route.query.page) {
      this.fetch(Number(this.$route.query.page))
    } else {
      this.fetch(1)
    }
  },
  methods: {
    search(word) {
      this.searchItem = word
      if (this.searchItem.length > 2 || this.searchItem.length === 0) this.fetch(1)
    },
    async fetch(page) {
      this.isBusy = true
      const response = await dbUser.getAll({
        page,
        perPage: this.perPage,
        paginate: true,
        filters: { word: this.searchItem },
      })
      this.items = response.data
      this.totalItems = response.total
      this.isBusy = false
    },
    onPagination(page) {
      this.fetch(page)
      this.$router.replace({ query: { page } })
    },
    onPerPage(size) {
      this.perPage = size
      this.fetch(1)
      this.$router.replace({ query: { page: 1 } })
    },
  },
}
</script>
